.homeAnchor {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: rgba(58, 90, 64, 0.2);
  border-radius: 10px 0 0 10px;
  padding: 1em 0.7em 1em 0.5em;
  cursor: pointer;
  font-size: 1.5em;
  color: var(--color-primary-darker);
}

.tooltip [class$="inner"] {
  background-color: var(--color-primary-dark);
  font-weight: 500;
}

.tooltip [class$="arrow"] {
  display: none;
}

.icon {
  transition: all 0.2s;
}
.icon:hover {
  transform: scale(1.2);
  color: black;
}
