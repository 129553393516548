body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --tablet-breakpoint: 481px;
  --large-screen-breakpoint: 769px;
  --color-primary: rgb(88, 129, 87);
  --color-primary-light: rgb(163, 177, 138);
  --color-primary-lighter: rgb(218, 215, 205);
  --color-primary-dark: rgb(58, 90, 64);
  --color-primary-darker: rgb(52, 78, 65);
  --color-secondary: rgb(108, 117, 125);
  --color-secondary-light: rgb(206, 212, 218);
  --color-secondary-lighter: rgb(233, 236, 239);
  --color-secondary-dark: rgb(73, 80, 87);
  --color-secondary-darker: rgb(52, 58, 64);
  --color-bg: #fafafa;
  --color-bg-dark: #f0f0f0;
  min-width: 429px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* https://coolors.co/palette/dad7cd-a3b18a-588157-3a5a40-344e41 green theme*/
/* https://coolors.co/palette/f8f9fa-e9ecef-dee2e6-ced4da-adb5bd-6c757d-495057-343a40-212529 grey theme */
/* consider danger: https://coolors.co/palette/f94144-f3722c-f8961e-f9844a-f9c74f-90be6d-43aa8b-4d908e-577590-277da1 F94144 */

@font-face {
  font-family: black-chancery;
  src: url(./resources/fonts/blackchancery.ttf);
}

@font-face {
  font-family: canterbury;
  src: url(./resources/fonts/canterbury.ttf);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-shadow-into-light {
  font-family: "Shadows Into Light", cursive;
}

a {
  color: var(--color-primary-darker) !important;
}
