.referenceSection {
  background-color: var(--color-bg);
  height: 700px;
}

.referenceContainer {
  line-height: 1.8em;
  margin: auto;
  width: 100%;
}

.quotationMark {
  position: absolute;
  font-size: 2em;
  color: var(--color-primary-darker);
}

.quotationMark:first-child {
  left: 0;
  top: 20px;
}

.quotationMark:last-child {
  right: 0;
  bottom: 20px;
}
.referenceContainer .referenceLetter {
  text-align: justify;
}

.referrer {
  font-size: 1.1em;
  margin-top: 0.2em;
  margin-right: auto;
  font-weight: 600;
  font-style: italic;
}

@media only screen and (min-width: 481px) {
  /* .referenceContainer {
    height: 390px;
    line-height: 1.8em;
  } */
  .referrer {
    font-size: 1.2em;
  }

  .quotationMark {
    font-size: 2.5em;
  }
}
@media only screen and (min-width: 769px) {
  .referenceContainer {
    width: 85%;
    font-size: 1.1em;
    line-height: 1.8em;
  }
  .referenceSection {
    height: 600px;
  }
  .referrer {
    margin-top: 0.5em;
  }
  .quotationMark:first-child {
    left: 5%;
    top: 20px;
  }

  .quotationMark:last-child {
    right: 5%;
    bottom: 20px;
  }
}
@media only screen and (min-width: 996px) {
  .referenceContainer {
    font-size: 1.2em;
    line-height: 2em;
  }
}
