.container {
  background-color: var(--color-bg);
}
.infoContainer {
  margin: auto;
}

.content {
  display: flex;
}
.school {
  font-family: canterbury;
  font-size: 3em;
}

.degree {
  font-family: canterbury;
  font-size: 2em;
}

.major,
.notes {
  font-family: black-chancery;
  font-size: 1.2em;
  font-style: italic;
}

.largeTime {
  margin: auto;
  font-family: "Sometype Mono", monospace;
  font-size: 1.2em;
  font-style: italic;
}

@media only screen and (min-width: 481px) {
  .content {
    height: 400px;
  }
}

@media only screen and (min-width: 1000px) {
  .content {
    height: 500px;
  }
}
