.textAndLineContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.horizontalLine {
  flex: 1;
  border-top: 1px dashed dimgray;
}

.horizontalLine:first-child {
  margin-right: 0.5em;
}

.horizontalLine:last-child {
  margin-left: 0.5em;
}
