footer {
  position: relative;
  background: var(--color-bg-dark);
  height: 350px;
  overflow: hidden;
  padding: 2em;
}

.author {
  color: var(--color-secondary);
  font-size: 0.9em;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
}

.author:hover {
  color: black;
}

.content {
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contact {
  gap: 1rem !important;
  justify-content: center;
  font-size: 1.4em;
  cursor: pointer;
}

.quote {
  font-size: 0.9em;
  color: var(--color-secondary-dark);
  font-style: italic;
  font-family: "Times New Roman", Times, serif;
  text-align: end;
  margin-top: auto;
}

.quote:hover {
  color: black;
}

.wave {
  position: absolute;
  height: 50%;
  width: 500px;
  bottom: 0;
  left: 0;
  background-color: var(--color-primary-light);
  opacity: 0.6;
}

.wave::before,
.wave::after {
  content: "";
  display: block;
  position: absolute;
}

.wave::before {
  width: 300px;
  height: 400px;
  border-top-right-radius: 100%;
  top: -240px;
  background-color: var(--color-primary-light);
}
.wave::after {
  width: 300px;
  height: 400px;
  border-bottom-left-radius: 100%;
  left: 247px;
  background-color: var(--color-bg-dark);
  top: -170px;
}
