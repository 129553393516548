.container {
  position: fixed;
  right: 1rem;
  bottom: 2em;
  background-color: rgba(58, 90, 64, 0.2);
  color: var(--color-primary);
  display: flex;
  cursor: pointer;
  font-size: 2.8rem;
  border-radius: 50%;
  padding: 0.4rem;
  z-index: 10000;
  transition: all 0.3s;
}

.container:hover {
  transform: scale(1.1);
}
