.title {
  font-weight: bold;
  font-size: 30px;
}

.roleTime {
  color: var(--color-secondary);
  font-size: 15px;
  font-style: italic;
}

.roleLocation {
  color: var(--color-secondary);
  font-size: 15px;
}

/* Job Description */
.descriptionText {
  color: var(--color-secondary);
  text-align: start;
  line-height: 1.5em;
  margin-top: 0.8em;
  transition: all 0.2s;
}

.descriptionText:first-child {
  margin-top: 0;
}

.descriptionText:hover {
  color: black;
}

.descriptionText::first-letter {
  font-weight: bold;
}

.projectName {
  height: 200px;
  display: flex;
  border: 1px dashed black;
  border-radius: 10px;
  min-width: 200px;
  max-width: 400px;
  margin: auto;
}

.projectName > div {
  margin: auto;
}

.rightCol {
  padding-left: 0;
}

/* SKILLS */

.skill {
  border-radius: 5px;
  padding: 0.3em 1em;
  font-weight: bold;
  border: 1px solid black;
}

@media only screen and (min-width: 996px) {
  .rightCol {
    border-left: 5px solid var(--color-secondary-light);
    padding-left: 1em;
  }
}
