.userSummaryContainer {
  font-size: 1em;
}

.name {
  margin-top: 0;
  font-size: 2.3em;
  font-weight: bolder;
}

.summary {
  font-weight: bold;
  font-size: 1.2em;
}

.location {
  display: flex;
  align-items: center;
}
